import React, { useEffect, useState } from "react";
import img2 from "../assets/images/minerals.jpg";
import img3 from "../assets/images/agriculture.jpg";
import img4 from "../assets/images/minerals2.jpg";
import img5 from "../assets/images/1 (1).jpeg";
import img6 from "../assets/images/1 (4).jpeg";
import img7 from "../assets/images/agriculture2.jpg";
import img8 from "../assets/images/1 (2).jpeg";
import img9 from "../assets/images/1 (3).jpeg";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import GoToTop from "../components/Top";
import img11 from "../assets/images/zinc2.jpeg";
import img10 from "../assets/images/Graphite2.jpeg";
import img12 from "../assets/images/lead.jpeg";

function Home() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const animateLoop = (achieveId, targetId) => {
      const achieveDiv = document.getElementById(achieveId);
      const targetDiv = document.getElementById(targetId);

      achieveDiv?.style.setProperty("display", "block");
      targetDiv?.style.setProperty("display", "none");
      achieveDiv?.classList.add("fadeIn");

      setTimeout(() => {
        achieveDiv?.style.setProperty("display", "none");
        targetDiv?.style.setProperty("display", "block");
        targetDiv?.classList.add("fadeIn");

        setTimeout(() => {
          targetDiv?.style.setProperty("display", "none");
          achieveDiv?.style.setProperty("display", "block");
          achieveDiv?.classList.add("fadeIn");

          setTimeout(() => {
            animateLoop(achieveId, targetId);
          }, 3000);
        }, 3000);
      }, 3000);
    };
    animateLoop("achieve", "target");
    animateLoop("achieve1", "target1");
    animateLoop("achieve2", "target2");
  }, []);

  return (
    <>
      <div className="">
        {/* <!-- Nav section  --> */}
        <NavBar />

        {/* <!-- slider --> */}
        <div id="banner">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={2500}
            controls={false}
            indicators={false}
            fade
          >
            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src={img2}
                alt="First slide"
              />
              <Carousel.Caption
                className="txt-center"
                style={{ top: "35%", left: "15%" }}
              >
                <h2 style={{color: "#281f1c"}}>Our Mission</h2>
                <p className="fs-4">Mineral Trading for a Sustainable Future</p>
              </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src={img10}
                alt="Second slide"
              />
              <Carousel.Caption
                className="text-center"
                style={{ top: "35%", left: "10%" }}
              >
                <h2>Our Mission</h2>
                <p className="fs-4">Mineral Trading for a Sustainable Future</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src={img11}
                alt="Second slide"
              />
              <Carousel.Caption
                className="text-center"
                style={{ top: "35%", left: "10%" }}
              >
                <h2>Our Mission</h2>
                <p className="fs-4">Mineral Trading for a Sustainable Future</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src={img12}
                alt="Second slide"
              />
              <Carousel.Caption
                className="text-center"
                style={{ top: "35%", left: "10%" }}
              >
                <h2>Our Mission</h2>
                <p className="fs-4">Mineral Trading for a Sustainable Future</p>
              </Carousel.Caption>
            </Carousel.Item> */}

          </Carousel>
        </div>

        {/* <!-- values section --> */}
        <div
          className="container-fluid mt-5"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="container">
            <div className="row p-3 animateTarget">
              <div className="col-lg-4 text-center">
                <div id="achieve" className="achieve m-1 p-2">
                  <h3 id="achieveValue">Global Reach</h3>
                  <p>100+ suppliers and off-takers spanning countries</p>
                </div>

                <div id="target" className="target m-1 p-2">
                  <h3 id="targetValue">Sustainability</h3>
                  <p>
                    A remarkable 20% reduction in our carbon footprint over the
                    past five years reflects our commitment to sustainability.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 text-center">
                <div id="achieve1" className="achieve m-1 p-2">
                  <h3 id="achieveValue">Traded Annually</h3>
                  <p>Over 30,000 tons</p>
                </div>

                <div id="target1" className="target m-1 p-2">
                  <h3 id="achieveValue">Traded Annually</h3>
                  <p>Over 30,000 tons</p>
                </div>
              </div>

              <div className="col-lg-4 text-center">
                <div id="achieve2" className="achieve m-1 p-2">
                  <h3>180 (directly & indirectly)</h3>
                  <p>Jobs Created in 2023</p>
                </div>

                <div id="target2" className="target m-1 p-2">
                  <h3 id="targetValue">
                    Promoting Environmental Sustainability
                  </h3>
                  <p>
                    Planting over 10,000 trees underscores our dedication to
                    environmental preservation and green initiatives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- purpose-section --> */}
        <div
          className="purpose-section container"
          style={{ boxShadow: "0px 0px 2px 3px silver" }}
        >
          <div className="card py-3 my-5">
            <h2>Our Purpose</h2>
            <p>
              To be your trusted partner in the world of physical commodity
              trading across multiple sectors. Our mission is to bridge the
              global supply and demand gap leveraging Africa’s wealth of
              resources
            </p>
          </div>
        </div>

        {/* <!-- card rows section --> */}
        <h3 className="text-center fs-2 pb-3">Our Services Include:</h3>

        <div
          className="container-fluid mb-5"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="container">
            <div
              className="row justify-content-between"
              style={{ columnGap: "80px", rowGap: "20px" }}
            >
              <div className="col-lg-4 col-12">
                <div
                  className="card py-4"
                  style={{ backgroundColor: "whitesmoke" }}
                >
                  <h2>TRADING</h2>
                  <p>
                    At <b>OAT</b> , we believe in the power of trade to unlock
                    opportunities and drive economic growth. Our extensive
                    network, market insights, and unwavering dedication enable
                    us to connect global markets, facilitate seamless
                    transactions, and deliver value to our clients and partners.
                  </p>
                </div>
              </div>
              <div className="col-lg-7 col-12 card-row-img my-3">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-interval="2500"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="d-block w-100 carousel-image d-block w-100">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img4}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="d-block w-100 carousel-image d-block w-100">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img5}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="d-block w-100 carousel-image d-block w-100">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img6}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="container-fluid mb-5"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="container">
            <div
              className="row justify-content-between"
              style={{ columnGap: "80px", rowGap: "20px" }}
            >
              <div className="col-lg-4 col-12">
                <div
                  className="card py-4"
                  style={{ backgroundColor: "whitesmoke" }}
                >
                  <h2>MARKETING OF COMMODITIES</h2>
                  <p>
                    With our extensive knowledge and insight into Africa's
                    wealth of natural resources, our marketing business focuses
                    on sourcing and marketing these commodities from where they
                    are plentiful to where they are needed.
                  </p>
                </div>
              </div>
              <div className="col-lg-7 col-12 card-row-img my-3">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-interval="2500"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="d-block w-100 carousel-image d-block w-100">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img7}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="d-block w-100 carousel-image d-block w-100">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img8}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="d-block w-100 carousel-image d-block w-100">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img9}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- corporate section --> */}
        <div className="container mb-5">
          <div className="row justify-content-center gap-4 my-4">
            <div className="col-12 text-center modal-a mt-3">
              <Link data-bs-toggle="modal" data-bs-target="#exampleModal">
                Buy Commodities? Click Here
              </Link>
            </div>

            <div className="col-12 text-center modal-a">
              <Link data-bs-toggle="modal" data-bs-target="#exampleModal1">
                Sell a Commodity? Click Here
              </Link>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog contact-form modal-dialog-centered">
            <div
              className="modal-content"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="modal-header">
                <h1
                  className="modal-title fs-5 text-center"
                  id="exampleModalLabel"
                >
                  Request Quotation for Commodity?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  action="mailto:uadelodun@gmail.com"
                  method="post"
                  enctype="text/plain"
                  className="row"
                  style={{ rowGap: "20px" }}
                >
                  <div className="col-md-6">
                    <div>
                      <label for="name">Full name *</label>
                      <br />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Full Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="email">Email *</label>
                      <br />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="product">Product *</label>
                      <br />
                      <input
                        type="text"
                        id="product"
                        name="product"
                        placeholder="Commodity Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="qty">Quantity *</label>
                      <br />
                      <input
                        type="number"
                        id="qty"
                        name="quantity"
                        placeholder="Quantity in tons"
                        min="1"
                        require
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="location">Location *</label>
                      <br />
                      <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Location"
                        required
                      />
                    </div>
                  </div>

                  <div className="mt-md-4 mb-md-5 my-2">
                    <button id="contact-btn" type="submit" value="Send">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal for seller --> */}
        <div
          className="modal fade"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog contact-form modal-dialog-centered">
            <div
              className="modal-content"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="modal-header">
                <h1
                  className="modal-title fs-5 text-center"
                  id="exampleModalLabel"
                >
                  Sell Commodity?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  action="mailto:uadelodun@gmail.com"
                  method="post"
                  enctype="text/plain"
                  className="row"
                  style={{ rowGap: "20px" }}
                >
                  <div className="col-md-6">
                    <div>
                      <label for="name">Full name *</label>
                      <br />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Full Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="email">Email *</label>
                      <br />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="product">Product *</label>
                      <br />
                      <input
                        type="text"
                        id="product"
                        name="product"
                        placeholder="Commodity Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="qty">Quantity *</label>
                      <br />
                      <input
                        type="number"
                        id="qty"
                        name="quantity"
                        placeholder="Quantity in tons"
                        min="0"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="location">Location *</label>
                      <br />
                      <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Location"
                        required
                      />
                    </div>
                  </div>

                  <div className="mt-md-4 mb-md-5 my-2">
                    <button id="contact-btn" type="submit" value="Send">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- footer --> */}
        <Footer />
      </div>

      <GoToTop />
    </>
  );
}

export default Home;
