import { useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/images/logo2.png";

function NavBar() {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const navbar = document.getElementById("mynav");

      if (scrollPosition > 0) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light" id="mynav">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={img1} alt="" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSS"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse ms-5 ps-5" id="navbarSS">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to={"/about"}>
                    about us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/metals"}>
                    our metals
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/faq"}>
                    FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/contact"}>
                    contact
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to={"/career"}>
                  career
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default NavBar;
