import React from "react";
import NavBar from "../components/Nav1";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import GoToTop from "../components/Top";

function Contact() {
  return (
    <>
      <div className="">
        {/* <!-- Nav section  --> */}
        <NavBar />

        <div className="container-fluid">
          <div className="container">
            <div className="faq contact m-md-5">
              <div>
                <nav
                  style={{ "--bs-breadcrumb-divider": ">" }}
                  aria-label="breadcrumb"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Oba of All Trades</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span id="contact-li">Contact Us</span>
                    </li>
                  </ol>
                </nav>
              </div>
              <p>
                Join us on a journey of growth, sustainability, and mutual
                prosperity. Discover the world of opportunities that awaits with
                Oba of All Trades (OAT).
              </p>

              <h1>Contact us</h1>
              <p>
                Find our contact details, answers to some frequently asked
                questions <Link to="/faq">FAQ</Link>, and get in touch.
              </p>

              <div className="contact-form m-md-5 p-md-5 p-2">
                <p>
                  If your question isn't answered here{" "}
                  <Link to="/faq">FAQ</Link> , or elsewhere on this site, please
                  send us a message.
                </p>
                <form
                  action="mailto:uadelodun@gmail.com"
                  method="post"
                  enctype="text/plain"
                  className="row"
                  style={{ rowGap: "20px" }}
                >
                  <div className="col-md-6">
                    <div>
                      <label for="name">Full name *</label>
                      <br />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Full Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <label for="email">Email *</label>
                      <br />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div>
                      <label for="message">Message *</label>
                      <br />
                      <textarea
                        placeholder="Enter your inquiry here to get a quote"
                        id="message"
                        name="message"
                        rows="5"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-md-4 mb-md-5 my-2">
                    <button id="contact-btn" type="submit" value="Send">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="container">
            <div className="row py-5" style={{ rowGap: "20px" }}>
              <div className="col-md-4">
                <div className="contactImg">
                  <Icon icon="solar:phone-outline" width={"40px"} />
                  <span>Call Us</span>
                  <br></br>
                  <br></br>
                  <a href="tel:+2348139994536">(234) 813-9994-536</a>
                  <br></br>
                  <br></br>
                  <a href="tel:+2348069646641">(234) 806-9646-641</a>
                  <br></br>
                  <br></br>
                  <a href="tel:+2348068355757">(234) 806-8355-757</a>
                </div>
              </div>

              <div className="col-md-4">
                <div className=" contactImg">
                  <Icon icon="system-uicons:location" width={"40px"} />
                  <span>Our Location</span>
                  <p>Lagos: 16 Babatunde Jose Street, Victoria Island</p>
                  <p>Abuja: Suit 36&37 Yasuha Plaza, Wuse 2, Abuja</p>
                  <p>Kwara: 13A Offa Road, GRA, Ilorin</p>
                </div>
              </div>

              <div className="col-md-4">
                <div className=" contactImg">
                  <Icon icon="line-md:email" width={"40px"} />
                  <span>Email</span>
                  <p>sales@obaofalltrades.com</p>
                  <p>hr@obaofalltrades.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- footer --> */}
        <Footer />
      </div>

      <GoToTop />
    </>
  );
}

export default Contact;
