import React from "react";
import NavBar from "../components/Nav1";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import GoToTop from "../components/Top";
import img1 from "../assets/images/lithium2.jpeg";
import img2 from "../assets/images/lithium.jpeg";
import img3 from "../assets/images/lead.jpeg";
import img4 from "../assets/images/lead2.jpeg";
import img6 from "../assets/images/lead3.jpeg";
import img7 from "../assets/images/zinc.jpeg";
import img8 from "../assets/images/zinc2.jpeg";
import img9 from "../assets/images/mang.jpeg";
import img10 from "../assets/images/mang2.jpeg";
import img11 from "../assets/images/mang3.jpeg";
import img12 from "../assets/images/Graphite.jpeg";
import img13 from "../assets/images/Graphite2.jpeg";
import img14 from "../assets/images/Copper.jpeg";
import img15 from "../assets/images/Copper2.jpeg";
import img16 from "../assets/images/Copper3.jpeg";
import img5 from "../assets/images/tin.jpeg";
import img17 from "../assets/images/tin2.jpeg";
import img18 from "../assets/images/tin3.jpeg";

function Commodities() {
  return (
    <>
      <div className="">
        {/* <!-- Nav section  --> */}
        <NavBar />

        <div className="container-fluid">
          <div className="container">
            <div className="faq contact m-md-5">
              <div>
                <nav
                  style={{ "--bs-breadcrumb-divider": ">" }}
                  aria-label="breadcrumb"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Oba of All Trades</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span id="contact-li">Metals</span>
                    </li>
                  </ol>
                </nav>
              </div>
              <p>OUR METALS</p>

              {/* <!-- Lithium section --> */}
              <div className="container mt-5" style={{ background:"whitesmoke" }}>
                <h1>Lithium</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img1}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img2}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Lead section --> */}
              <div className="container mt-5">
                <h1>Lead</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img3}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img4}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img6}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Zinc section --> */}
              <div className="container mt-5" style={{ background:"whitesmoke" }}>
                <h1>Zinc</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img7}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img8}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Manganese section --> */}
              <div className="container mt-5">
                <h1>Manganese</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img9}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img10}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img11}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Graphite section --> */}
              <div className="container mt-5" style={{ background:"whitesmoke" }}>
                <h1>Graphite</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img12}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img13}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

               {/* <!-- Copper section --> */}
               <div className="container mt-5">
                <h1>Copper</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img14}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img15}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img16}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

               {/* <!-- Tin section --> */}
               <div className="container mt-5" style={{ background:"whitesmoke" }}>
                <h1>Tin</h1>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img5}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img17}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <div className="d-block w-100 card-row-img">
                        <div className="inner-img">
                          <img
                            id="cardSlideImg"
                            src={img18}
                            alt="business model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- agriculture section --> */}
              {/* <div className="container mt-4">
                <h1>Agriculture</h1>
                <p>
                  In the Agriculture sector,<b> Oba of All Trades (OAT)</b>{" "}
                  plays a vital role in facilitating the trading of agricultural
                  commodities in Nigeria.
                </p>
                <p>
                  Nigeria is a country blessed with abundant agricultural
                  resources and has a strong agrarian tradition. The
                  agricultural sector in Nigeria contributes significantly to
                  the nation’s economy, providing employment opportunities and
                  ensuring food security.
                </p>
                <p>
                  At <b>OAT</b>, we recognize the importance of the agriculture
                  sector in Nigeria and actively engage in trading various
                  agricultural commodities within and outside the country.
                </p>

                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <h3 className="pt-1 text-center">Grains</h3>
                      <p>
                        Nigeria is known for the production of grains such as
                        maize, rice, millet, and sorghum. These grains serve as
                        staple foods for the Nigerian population and are in high
                        demand both domestically and internationally. <b>OAT</b>{" "}
                        works closely with farmers and grain traders to ensure a
                        smooth supply chain, from production to distribution,
                        and to meet the demands of buyers across the country.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <h3 className="pt-1 text-center">Oilseeds</h3>
                      <p>
                        Oilseeds, including soybeans, groundnuts (peanuts), and
                        palm kernels, are essential crops in Nigeria. These
                        oilseeds are not only used for cooking oil production
                        but also serve as crucial ingredients in the animal feed
                        industry. <b>OAT</b>
                        facilitates the trading of oilseeds, connecting buyers
                        and sellers and ensuring a reliable supply chain for
                        both domestic consumption and export purposes.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <h3 className="pt-1 text-center">Pulses</h3>
                      <p>
                        Pulses, such as beans, lentils, and cowpeas, are highly
                        nutritious and have a significant role in Nigeria's
                        diet. <b>OAT</b>
                        actively engages in trading pulses, collaborating with
                        farmers and processors to meet the growing demand for
                        these protein-rich commodities. We ensure efficient
                        procurement, quality control, and timely delivery of
                        pulses to buyers within Nigeria and abroad.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <h3 className="pt-1 text-center">
                        Cashew and Cashew Nut
                      </h3>
                      <p>
                        Nigeria is one of the largest producers of cashew in the
                        world. Cashew nuts are in high demand globally due to
                        their nutritional value and culinary uses. <b>OAT</b>{" "}
                        works closely with cashew farmers, processors, and
                        exporters to ensure the efficient trading of cashew and
                        cashew nuts. We facilitate the supply chain, ensuring
                        quality control, packaging, and timely delivery of these
                        commodities to international markets.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <h3 className="pt-1 text-center">Cassava</h3>
                      <p>
                        Cassava is a versatile staple crop in Nigeria, with
                        diverse applications in food processing, animal feed,
                        and industrial sectors. <b>OAT</b> engages in the
                        trading of cassava and cassava derivatives such as
                        cassava flour, starch, and chips. We collaborate with
                        cassava farmers, processors, and end-users to facilitate
                        a seamless supply chain and promote value addition to
                        cassava-based products.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                      <h3 className="pt-1 text-center">Sugar</h3>
                      <p>
                        Nigeria has a substantial demand for sugar, both for
                        household consumption and industrial applications.{" "}
                        <b>OAT</b> participates in the trading of sugar, working
                        closely with sugar refineries, distributors, and
                        end-users. We facilitate the sourcing, logistics, and
                        distribution of sugar, ensuring a steady supply to meet
                        the needs of the Nigerian market.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="p-3 mt-3"
                  style={{ backgroundColor: "whitesmoke" }}
                >
                  <p>
                    In addition to facilitating commodity trading, <b>OAT</b> is
                    committed to promoting sustainable agricultural practices
                    and supporting the development of local farming communities
                    in Nigeria. We actively seek partnerships that empower
                    farmers, promote fair trade, and contribute to the overall
                    growth and sustainability of the agriculture sector in the
                    country.
                  </p>
                  <p>
                    By leveraging our extensive network, market expertise, and
                    understanding of Nigeria’s agricultural landscape,{" "}
                    <b>OAT</b> aims to drive economic growth, enhance food
                    security, and foster a thriving agricultural sector in
                    Nigeria.
                  </p>
                  <p>
                    We are proud to be a part of Nigeria’s agricultural
                    ecosystem and remain dedicated to connecting buyers and
                    sellers, creating value, and contributing to the sustainable
                    growth of the agriculture sector in Nigeria and beyond.
                  </p>
                </div>
              </div> */}

              {/* <!-- infrastructure section --> */}
              {/* <!-- <div className="container mt-5">
                        <h1>Infrastructure</h1>
                        <p>
                            The Infrastructure sector in Nigeria plays a crucial role in supporting economic growth,
                            enhancing connectivity, and
                            improving the overall quality of life for its citizens.
                            Oba of All Trades <b>(OAT)</b> recognizes the significance of the Infrastructure sector and
                            actively engages in various aspects
                            of infrastructure development in Nigeria.
                        </p>
                        <p>
                            Through our comprehensive trading services, <b>OAT</b> aims to empower Nigeria’s
                            infrastructure
                            development by ensuring a
                            steady supply of quality building materials, providing access to modern construction
                            equipment, supporting real estate
                            projects, and promoting sustainable practices.
                        </p>

                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                                    <h3 className="pt-1 text-center">Building and Construction Materials</h3>
                                    <p>
                                        OAT facilitates the trading of essential building materials, including cement,
                                        steel, granite, sharp sand, asphalt,
                                        laterite, and waste water pipes. We work closely with manufacturers, suppliers,
                                        and construction companies to ensure a
                                        reliable supply of high-quality materials for infrastructure projects. Our
                                        extensive network enables us to efficiently
                                        source, transport, and distribute these materials across Nigeria.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                                    <h3 className="pt-1 text-center">Energy Infrastructure</h3>
                                    <p>
                                        A reliable and efficient energy infrastructure is essential for powering
                                        industries, businesses, and households. OAT
                                        contributes to the energy sector in Nigeria by trading resources like renewable
                                        energy technologies. We support the
                                        development of power generation, transmission, and distribution infrastructure,
                                        working with energy companies,
                                        utilities, and government entities to meet the growing demand for electricity.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                                    <h3 className="pt-1 text-center">Construction Equipment</h3>
                                    <p>
                                        OAT also plays a crucial role in the trading of construction equipment,
                                        providing access to a wide range of machinery
                                        and tools required for various construction activities. From earthmoving
                                        equipment and cranes to concrete mixers and
                                        scaffolding, we connect contractors, builders, and construction firms with
                                        reliable equipment suppliers. By facilitating
                                        the trade of construction equipment, OAT helps enhance construction
                                        productivity, and safety on
                                        sites.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                                    <h3 className="pt-1 text-center">Urban Development</h3>
                                    <p>
                                        OAT recognizes the significance of real estate and urban development in shaping
                                        vibrant and sustainable communities. We
                                        actively participate in the trading and development of real estate projects,
                                        collaborating with developers, architects,
                                        and urban planners. Our involvement includes trading land, connecting investors
                                        with  opportunities, and
                                        supporting the construction of residential, commercial, and industrial
                                        properties.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                                    <h3 className="pt-1 text-center">Steel</h3>
                                    <p>
                                        Steel is the backbone of industrialization, construction, and infrastructure
                                        projects. Nigeria's steel industry has
                                        tremendous potential for growth and self-sufficiency. OAT actively engages in
                                        the trading of steel, working closely with
                                        local manufacturers, construction companies, and infrastructure developers. We
                                        ensure the availability of quality steel
                                        products, support local value addition, and foster the growth of Nigeria's steel
                                        sector.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className=" corporate-card innerCard p-3 m-3 gallery-card">
                                    <h3 className="pt-1 text-center">Sustainable Practices and Innovation</h3>
                                    <p>
                                        At OAT, we prioritize sustainable practices and innovation in the infrastructure
                                        and construction sectors. We promote
                                        the use of eco-friendly and energy-efficient building materials and support the
                                        adoption of green building practices.
                                        Additionally, we facilitate the trading of innovative construction technologies
                                        and solutions that enhance efficiency,
                                        durability, and environmental performance. 
                                    </p>
                                </div>
                            </div>



                        </div>
                    </div> --> */}
            </div>
          </div>
        </div>

        {/* <!-- footer --> */}
        <Footer />
      </div>

      <GoToTop />
    </>
  );
}

export default Commodities;
