import React from "react";
import NavBar from "../components/Nav1";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import GoToTop from "../components/Top";

function About() {
  return (
    <>
      <div className="">
        {/* <!-- Nav section  --> */}
        <NavBar />
        <div className="container-fluid">
          <div className="container">
            <div className="faq contact m-md-5 m-1">
              <div>
                <nav
                  style={{ "--bs-breadcrumb-divider": ">" }}
                  aria-label="breadcrumb"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Oba of All Trades</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span id="contact-li">About Us</span>
                    </li>
                  </ol>
                </nav>
              </div>
              {/* <p>WHO WE ARE</p> */}

              <h1 className="mt-5">WHO WE ARE</h1>
              <p>
                Oba of All Trades is a leading trader of minerals needed for the
                energy transition, such as Lithium, Copper, Zinc, Lead,
                Manganese, Graphite, and Tin. We have a network of suppliers and
                customers in the energy transition sector, and we are committed
                to providing our customers with the highest quality products and
                services at competitive prices.
              </p>
              <p>
                We understand the importance of these minerals for the future of
                energy, and we are committed to playing our role in the global
                transition to a clean energy future. We work closely with our
                customers to understand their needs and provide them with the
                minerals they need to succeed.
              </p>
              <p>
                OAT is committed to fair trade, environmental sustainability,
                and technological innovation. With tailored services for our
                partners, suppliers, off-takers, and investors, we consistently
                deliver exceptional results in:
              </p>
              <ul>
                <li>Commodity Trading</li>
                <li>Logistics and Transportation</li>
                <li>Data Mining and Analytics</li>
                <li>Ethical Trade Practices</li>
                <li>Environmental Sustainability Initiatives</li>
                <li>Technological Innovation</li>
                <li>Managed- Metal Marketplace</li>
              </ul>
            </div>

            {/* <!-- vision-section --> */}
            <div className="purpose-section m-md-5 m-1 mb-3">
              <div className="card mission px-3 py-1">
                <h2>Our Vision</h2>
                <p>
                  Bridging the world’s needs with Africa’s natural resources.
                </p>
              </div>
            </div>

            {/* <!-- mission-section --> */}
            <div className="purpose-section m-md-5 m-1">
              <div className="card mission px-3 py-1">
                <h2>Our Mission</h2>
                <p>
                  Bridging the global demand and supply gap, leveraging Africa's
                  natural resources.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* VALUE WE OFFER */}
        <div style={{ backgroundColor: "whitesmoke" }} className="mb-5">
          <div className="container">
            <div
              className="row justify-content-center m-4 p-3 faq"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <h1 className="mt-5">VALUE WE OFFER</h1>

              <div className="offer">
                <h3 className="text-center my-3">
                  Commodity Trading Expertise
                </h3>
                <ul type="square">
                  <li className="my-4">
                    <h4>One-Stop Shop</h4>
                    <p>
                      We provide a comprehensive and efficient one-stop shop for
                      all commodity trading needs, saving valuable time and
                      resources for our partners and investors.
                    </p>
                  </li>
                  <li className="my-4">
                    <h4> Fair Trade Commitment</h4>
                    <p>
                      Equitable compensation for all parties involved through
                      our fair trade practices is at the core of our business.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="offer">
                <h3 className="text-center my-3">Sustainable Innovation</h3>
                <ul type="square">
                  <li className="my-4">
                    <h4>Environmental Sustainability</h4>
                    <p>
                      By the implementation of various eco-friendly practices
                      and technologies, we foster environmental sustainability.
                    </p>
                  </li>
                  <li className="my-4">
                    <h4>Technological Advancements</h4>
                    <p>
                      From cutting-edge solutions to real-time market insights
                      for our esteemed clientele, technological advancement is
                      in our DNA.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* services section */}
        <div className="mb-5">
          <div className="container">
            <div className="row justify-content-center m-4 p-3 faq">
              <h1 className="mt-5">OUR SERVICES</h1>

              <div className="offer">
                <ul type="square">
                  <li className="my-4">
                    <h4>Sourcing and Procurement</h4>
                    <p>
                      Leveraging a vast network to source and procure diverse
                      commodities from across the globe.
                    </p>
                  </li>
                  <li className="my-4">
                    <h4>Logistics and transportation</h4>
                    <p>
                      Seamless and efficient transportation solutions are
                      managed by experienced professionals and powered by
                      advanced real-time tracking technology.
                    </p>
                  </li>
                  <li className="my-4">
                    <h4>Mineral and Exchange Platform</h4>
                    <p>
                      We are building a robust platform facilitating seamless
                      transactions and providing real-time market data and
                      analytics for suppliers and off-takers.
                    </p>
                  </li>
                  <li className="my-4">
                    <h4>Data Mining and Analytics</h4>
                    <p>
                      We utilize the data to drive informed decisions and
                      capitalize on emerging market trends.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Our Achievements */}
        <div style={{ backgroundColor: "whitesmoke" }} className="mb-5">
          <div className="container">
            <div
              className="row justify-content-center m-4 p-3 faq"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <h1 className="mt-5">OUR ACHEIVEMENTS</h1>

              <div className="offer">
                <ul type="square">
                  <li className="my-4">
                    <p>
                      With great pride, we lead the charge in Nigeria's business
                      landscape, integrating data mining and analytics platforms
                      to seize real-time opportunities and monitor market
                      trends. Our commitment to sustainability shines through
                      our efforts in renewable energy across all operational
                      fronts and our dedication to eco-conscious practices.
                    </p>
                  </li>
                  <li className="my-4">
                    <p>
                      Recognized by the Nigerian Chamber of Commerce and
                      Industry as one of the most ethical trading companies, we
                      solidify our commitment to fairness and integrity. The
                      Nigerian Green Award exemplifies our relentless dedication
                      to environmental sustainability, showcasing our passion
                      for preserving the planet.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- footer --> */}
        <Footer />
      </div>

      <GoToTop />
    </>
  );
}

export default About;
