import React from "react";
import NavBar from "../components/Nav1";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";

function Faq() {
  return (
    <>
      <div className="">
        {/* <!-- Nav section  --> */}
        <NavBar />

        <div className="container-fluid">
            <div className="faq">
                <div className="container">
                    <h1 className="my-3">Frequently asked questions</h1>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                                    aria-controls="flush-collapseOne">
                                    How do I become a supplier?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    If you are interested in becoming a supplier, please contact the OAT offices or any
                                    of our contact mobile lines and/or
                                    email address.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                    aria-controls="flush-collapseTwo">
                                    Are there any environmental or social concerns associated with mine? What about
                                    legal disputes or claims?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    No
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    What is the system of transporting mineral rock to the nearest port (i.e., Lagos
                                    etc.), rail or truck?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Truck
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour" aria-expanded="false"
                                    aria-controls="flush-collapseFour">
                                    Are there any other Minerals available in commercial quantities on your mine sites
                                    such as graphite, nickel, cobalt,
                                    columbite/ tantalite?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Yes, Gold
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive" aria-expanded="false"
                                    aria-controls="flush-collapseFive">
                                    What is the current security situation in and near your mines? Will Nigerians and
                                    foreigners be safe and secure working
                                    in the areas where your mines are situated or mines where aggregation purchases will
                                    have to be made i.e., Kogi,
                                    Nasarawa, Taraba
                                    etc.?
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Our company has institutional relationships with law enforcement agencies and close
                                    relationships with members of the
                                    community. Therefore, we have a secure working environment that allows foreigners to
                                    work.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix" aria-expanded="false"
                                    aria-controls="flush-collapseSix">
                                    What security threats do you face and how have you prepared against them?
                                </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    As the answer above ensures, we do not face security threats.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSeven" aria-expanded="false"
                                    aria-controls="flush-collapseSeven">
                                    Do you have mineral rights, licenses, certifications, and any other relevant permits
                                    that could facilitate transactions?
                                    How many mines do you have?
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    We produce and source lithium ores (both Lepidolites and Spodumene) from our assets
                                    and those of third parties. Our
                                    mining lease is currently being pursued with the Federal Ministry of Mines so we
                                    rely more on sourcing our lithium ores
                                    from the group of artisanal miners we work with.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingEight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseEight" aria-expanded="false"
                                    aria-controls="flush-collapseEight">
                                    Production capacity of Spodumene (or Kunzite), Lepidolite, and Petalite (or the
                                    quantity of mineral ore available).
                                </button>
                            </h2>
                            <div id="flush-collapseEight" className="accordion-collapse collapse"
                                aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    We are sourcing at a capacity of 4,000 metric tons per month.
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>

        {/* <!-- footer --> */}
        <Footer />
      </div>

      <GoToTop />
    </>
  );
}

export default Faq;
